/* @mixin modal-fullscreen() {
    padding: 0 !important; 
    
    .modal-dialog {
      width: 100%;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    
    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    
    .modal-body {
      overflow-y: auto;
    }
   
  }
  
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      
      .modal-fullscreen#{$infix} {
        @include modal-fullscreen();
      }
    
    }
  } */
  
  
  /* Styles for codepen */
  /* html {
    display: flex;
    height: 100%;
  }
  
  body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .btn-open-modal {
    margin-bottom: 0.5em;
  } */



  .modal-dialog-1 {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  
  .modal-content-1 {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  
  .modal-body-1 {
    overflow-y: auto;
  }