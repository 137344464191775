body {
  margin: 0;
  box-sizing: border-box;
}

.certificate3-bg {
  background: url(images/img-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 600px;
  margin: 40px auto;
  height: 463px;
  padding: 40px 0;
  text-align: center;
  color: #304c32;
}
.certificate3-bg img {
  padding: 0 0 20px;
}
.certificate3-bg h1 {
  font-size: 16px;
  padding: 10px 0 20px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
}
.certificate3-bg h2 {
  padding: 10px 0 0;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}
.certificate3-bg h3 {
  padding: 20px 0 0;
  font-style: italic;
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 34px;
}
.certificate3-bg h5 {
  padding: 20px 0 0;
  margin: 0;
  text-transform: uppercase;
}
.sign3-sec {
  display: flex;
  justify-content: space-around;
  padding: 30px 0 0;
}
.sign-sec-l,
.sign-sec-r {
  width: 20%;
  padding-top: 5px;
}
.sign-sec-l .sign-text,
.sign-sec-r .date-text {
  border-top: 1px solid #000;
}
.sign-sec-l .sign-img,
.sign-sec-r .date {
  padding-bottom: 6px;
}

.sign-sec-r .date{
  font-size: 13px;
}

.sign-sec-l .sign-img img {
  width: 63px;
  padding-bottom: 4px;
}
